<template>
  <div class="d-flex">
    <strategy-parts-ref-bar-dropdown
      @click-expand="expand = $event"
      class="mr-8"
    ></strategy-parts-ref-bar-dropdown>
    <div v-if="isInit" v-show="!expand" class="strategy-edit-wrap mx-auto">
      <!-- 更新情報 -->
      <div class="d-flex justify-end mt-2 mb-7 mr-5">
        <sheet-info
          v-model="sectionInfo"
          class="bottommost-section"
        ></sheet-info>
      </div>
      <!-- 戦略パーツ -->
      <div class="strategy-edit-main-container my-10 px-10">
        <v-container>
          <v-row class="mb-8">
            <!-- パーツタイトル -->
            <v-col
              cols="12"
              class="text-h5 font-weight-medium pb-1"
              align="center"
            >
              {{ section.label }}
            </v-col>
            <!-- パーツ説明文 -->
            <v-col cols="12" class="pa-0">
              <hr class="page-title-hr" />
            </v-col>
            <v-col cols="12" class="pt-1 mb-3" align="left">
              {{ section.description }}
            </v-col>
            <!-- パーツ表示部 -->
            <v-col class="d-flex justify-center">
              <section-resolver
                :elementId="section.dataRef"
                :name="strategyPartsMetaDict[section.dataRef].comp"
                :inputStatus="inputStatus"
                :ref="section.name"
                v-model="value[this.partsMeta.inputDataKey]"
                :hasWritePermission="hasWritePermission"
                :dataInit="dataInit"
                @update="needUpdate = true"
                @mounted="mounted = true"
              ></section-resolver>
            </v-col>
          </v-row>
          <v-row class="mb-6" justify="center">
            <!-- パーツ登録ボタン -->
            <v-col cols="5" align="right">
              <v-btn-accent
                :label="section.label + 'を登録'"
                icon="mdi-plus-circle-outline"
                @click="dialog.update = true"
              >
              </v-btn-accent>
            </v-col>
            <v-col cols="1"></v-col>
            <!-- パーツ削除ボタン -->
            <v-col cols="5" align="left">
              <v-btn-cancel
                label="削除"
                @click="dialog.delete = true"
              ></v-btn-cancel>
            </v-col>
          </v-row>
          <!-- 戻るボタン -->
          <v-row>
            <v-col align="center">
              <v-btn text color="btPrimary" dark @click="$router.back()">
                <v-icon size="32" left>mdi-chevron-left-circle-outline</v-icon>
                <span class="black--text">前のページに戻る</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <!-- 入力サンプル -->
        <reference-document-dialog></reference-document-dialog>
        <reference-image-dialog></reference-image-dialog>

        <!-- 更新確認ダイアログ -->
        <yes-no-dialog
          :message="'入力した内容で更新しますか？'"
          v-model="dialog.update"
          yesText="更新する"
          noText="キャンセル"
          center
          @clickYes="onClickUpdateBtn"
          @clickNo="dialog.update = false"
        />
        <!-- 削除確認ダイアログ -->
        <yes-no-dialog
          :message="section.label + 'を削除します。よろしいですか？'"
          v-model="dialog.delete"
          yesText="削除する"
          noText="キャンセル"
          center
          @clickYes="onClickDeleteBtn"
          @clickNo="dialog.delete = false"
        />

        <!-- 遷移確認ダイアログ -->
        <yes-no-dialog
          :message="'編集中のものは保存されませんが、よろしいですか？'"
          v-model="dialog.leaveConfirm"
          yesText="はい"
          noText="いいえ"
          center
          @clickYes="cb()"
          @clickNo="
            dialog.leaveConfirm = false;
            cb = null;
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import strategyPartsMetaDict, {
  StrategyPartsMetaDictItem,
} from "../../../utils/strategyPartsMetaDict";
import strategyPartsInitData from "../../../utils/strategyPartsInitData";

import SectionResolver from "@/components/util/SectionResolver";
import YesNoDialog from "../atoms/YesNoDialog.vue";
import SectionStatus from "../molecules/SectionStatus.vue";
import ReferenceDocumentDialog from "../atoms/ReferenceDocumentDialog.vue";
import ReferenceImageDialog from "../atoms/ReferenceImageDialog.vue";
import SheetInfo from "../organisms/SheetInfo.vue";
import StrategyPartsRefBarDropdown from "@/components/atomic/organisms/StrategyPartsRefBarDropdown.vue";

import VBtnAccent from "@/components/atomic/atoms/VBtnAccent.vue";
import VBtnCancel from "@/components/atomic/atoms/VBtnCancel.vue";

export default {
  props: ["paramSectionId"],
  name: "StrategyPartsEditPage3",
  components: {
    SectionResolver,
    YesNoDialog,
    SectionStatus,
    ReferenceDocumentDialog,
    ReferenceImageDialog,
    SheetInfo,
    StrategyPartsRefBarDropdown,
    VBtnAccent,
    VBtnCancel,
  },
  data() {
    return {
      isInit: false,
      dataInit: false,
      needUpdate: false, //更新API呼出の要否フラグ
      section: null,
      value: null,
      inputStatus: null,
      sectionInfo: null,
      strategyPartsMetaDict,
      partsMeta: new StrategyPartsMetaDictItem(),
      expand: false,
      cb: null,
      mounted: false,
      timerObj: null,
      dialog: {
        delete: false,
        update: false,
        moveConfirm: false,
        leaveConfirm: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "hasWritePermission",
      "project",
      "registeredSections",
      "editablePositioningMap",
      "registeredSection",
    ]),
  },
  methods: {
    ...mapActions([
      "getRegisteredSections",
      "deleteSelectedSection",
      "updateSelectedSection",
      "fetchSelectedSection",
    ]),
    async onClickDeleteBtn() {
      // 戦略パーツをプロジェクトから解除する
      this.unregisterSection();

      this.dialog.delete = false;
      this.needUpdate = false;

      this.moveToTopPage();
    },
    async onClickUpdateBtn() {
      // 更新処理
      this.updateSection();

      this.dialog.update = false;
      this.needUpdate = false;

      // トップページに遷移
      this.moveToTopPage();
    },
    async updateSection() {
      let body = {
        sectionId: this.section.sectionId,
        inputStatus: this.inputStatus,
      };

      // セクション情報を更新
      await this.updateSelectedSection(body);

      // 入力データを更新
      body = this.value;
      await this.$store.dispatch(this.partsMeta.updateAction, body);
    },
    async unregisterSection() {
      // 選択セクション情報を削除する
      this.deleteSelectedSection(this.section.sectionId);

      // 入力データを初期化する
      if (this.section.dataRef in strategyPartsInitData) {
        this.value[this.partsMeta.inputDataKey] =
          strategyPartsInitData[this.section.dataRef];
      }

      await this.$store.dispatch(this.partsMeta.updateAction, this.value);
    },
    moveToTopPage() {
      this.$router.push({
        name: "StrategyTopPage",
        params: { projectId: this.project.id },
      });
    },
    async getCurrentSection() {
      if (this.paramSectionId) {
        // paramSectionId がある場合は単体で取得する。
        await this.fetchSelectedSection(this.paramSectionId);
        this.section = this.registeredSection.section;
        this.sectionInfo = this.registeredSection.sectionInfo;
        this.inputStatus = this.registeredSection.inputStatus;
      } else {
        // リロード時など は paramSectionId がないので全体の中から選ぶ
        await this.getRegisteredSections(this.$route.params.projectId);
        var sections = this.registeredSections.sections.filter((item) => {
          if (item.section.dataRef == this.$route.params.partsName) {
            return true;
          }
        });

        if (sections.length > 0) {
          this.section = sections[0].section;
          this.sectionInfo = sections[0].sectionInfo;
          this.inputStatus = sections[0].inputStatus;
        }
      }
    },
    async init() {
      console.log("this.readonly:" + this.readonly);

      // セクションの一覧を取得
      await this.getCurrentSection();

      // メタ情報辞書から戦略パーツメタ情報を取得する
      this.partsMeta = strategyPartsMetaDict[this.section.dataRef];

      // データを取得する
      await this.$store.dispatch(this.partsMeta.fetchAction);
      this.value = this.$store.getters[this.partsMeta.getterKey];
      this.isInit = true;

      //データ初期化確認用タイマー
      this.timerObj = setInterval(() => {
        if (this.mounted) {
          this.dataInit = true;
          clearInterval(this.timerObj);
        }
      }, 1000);
    },
  },
  async created() {
    await this.init();
  },
  beforeDestroy() {
    if (this.timerObj) clearInterval(this.timerObj);
  },
  beforeRouteLeave(to, from, next) {
    if (this.needUpdate) {
      // コールバックを設定して遷移確認ダイアログを表示
      this.cb = next;
      this.dialog.leaveConfirm = true;
    } else {
      next();
    }
  },
};
</script>
<style lang="scss" scoped>
.strategy-edit-wrap {
  @include scrollAreaX();
  width: 100%;
  letter-spacing: 0.0125em;
}
.strategy-edit-main-container {
  margin: 0 auto;
  max-width: 1040px;
  min-width: 600px;
}
</style>
